import React, { useMemo } from "react"
import moment from "moment"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useTable } from "react-table"

import { User_users_by_pk_classrooms } from "../../hasura/queries/types/User"
import { userSelector, UserState } from "../../hasura/slices/user"
import { archiveClassroomAction, classroomSelector, ClassroomState } from "../../hasura/slices/classroom"
import { Cell, Header, HeaderCell, Table, TextCell } from "../common/tables/table"
import { QueryName } from "../../hasura/queryNames"

interface Props {
  classrooms: User_users_by_pk_classrooms[]
  setIsViewingArchivedClassrooms: (isViewingArchivedClassrooms: boolean) => void
}

const defaultColumn: any = {
  TextCell,
}

export default function ArchivedTable(props: Props) {
  const dispatch = useDispatch()

  const { classrooms } = props

  const { accessToken, user }: UserState = useSelector(userSelector)
  const { isQuerying }: ClassroomState = useSelector(classroomSelector)

  const unarchiveClassroom = async (id: number) => {
    await dispatch(archiveClassroomAction(accessToken!, id, user?.id!, false))
    props.setIsViewingArchivedClassrooms(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "display_name",
      },
      {
        Header: "# Students",
        accessor: (c: User_users_by_pk_classrooms) => c.students.length,
      },
      {
        Header: "Date",
        accessor: (c: User_users_by_pk_classrooms) =>
          `${moment(c.created_at).format("MMM D, YYYY")} to ${moment(c.updated_at).format("MMM D, YYYY")}`,
      },
      {
        Header: "Unarchive",
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data: classrooms,
    defaultColumn,
  })

  return (
    <Table cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, idx: number) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <HeaderCell key={idx} {...column.getHeaderProps()}>
                <Header>{column.render("Header")}</Header>
              </HeaderCell>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, idx: number) => {
          prepareRow(row)

          return (
            <tr key={idx} {...row.getRowProps()}>
              {row.cells.map((cell: any, idx2: number) => {
                if (cell.column.Header === "Unarchive") {
                  return (
                    <Cell key={idx2} {...cell.getCellProps()}>
                      <Button
                        disabled={isQuerying[QueryName.ArchiveClassroom]}
                        outline
                        color="primary"
                        onClick={() => unarchiveClassroom(cell.row.original.id)}
                      >
                        Unarchive Class
                      </Button>
                    </Cell>
                  )
                }

                return (
                  <Cell key={idx2} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Cell>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
