import React from "react"
import { Modal, ModalBody, Button } from "reactstrap"
import { useDispatch } from "react-redux"

import { removePresignedUrlAction } from "../../hasura/slices/classroom"

interface Props {
  url: string
  //   studentName: string
  downloadCsv: () => void
  handleClose: () => void
}

export default function ReportModal(props: Props) {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(removePresignedUrlAction())
    props.handleClose()
  }

  return (
    <Modal className="max-width-1000px w-100" fade={false} zIndex={2147483647} toggle={handleClose} isOpen size="xl">
      <ModalBody className="p-4 w-100 text-center position-relative min-height-1300px d-flex flex-column gap-10px">
        <div className="d-flex justify-content-between">
          {/* <div className="text-left">
            <h1 className="bold text-l mb-1">Share report</h1>
            <p>Give parents or other teachers access to {props.studentName}'s report with an optional message.</p>
          </div> */}
          <div />

          <div className="d-flex gap-10px align-items-start">
            <Button className="mr-2" color="primary" onClick={props.downloadCsv}>
              Download Words CSV
            </Button>

            <Button className="width-100px" color="dark" outline onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>

        <iframe style={{ backgroundColor: "white" }} className="w-100 h-100 flex-grow" src={props.url} />
      </ModalBody>
    </Modal>
  )
}
