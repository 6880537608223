import React, { useState, useMemo } from "react"
import isUndefined from "lodash/isUndefined"
import mean from "lodash/mean"
import meanBy from "lodash/meanBy"
import moment from "moment"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { useTable, useSortBy } from "react-table"

import CONFIG from "../../config"
import DangerModal from "../common/modals/danger"
import EditModal from "../common/modals/edit"
import ReportModal from "./reportModal"
import WrappedTooltip from "../common/tooltip"
import colors from "../../lib/colors"
import { NotificationId, setNotificationAction } from "../../hasura/slices/notification"
import { UserEvent } from "../../lib/userEventTypes"
import { User_users_by_pk_classrooms, User_users_by_pk_classrooms_students } from "../../hasura/queries/types/User"
import { classroomSelector, ClassroomState, fetchPresignedUrlForReportAction, updateUserClassroomAction } from "../../hasura/slices/classroom"
import { postSlackMessage, withCommas } from "../../lib/helpers"

import {
  deleteStudentAction,
  downloadStudentReportAction,
  dropStudentAction,
  fetchUserAction,
  insertUserEventAction,
  updatePasswordAction,
  updateUserDisplayNameAction,
  userSelector,
  UserState,
} from "../../hasura/slices/user"

// @ts-ignore
import expand from "../../lib/images/expand.png"
// @ts-ignore
import googleIcon from "../../lib/images/google.png"
// @ts-ignore
import dots from "../../lib/images/dots.svg"

interface Props {
  classroom: User_users_by_pk_classrooms
  student?: User_users_by_pk_classrooms_students
}

export default function StudentsTable(props: Props) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const { classroom } = props

  const { accessToken, user, hasPremium }: UserState = useSelector(userSelector)
  const { presignedUrlForReport }: ClassroomState = useSelector(classroomSelector)

  const [student, setStudent] = useState<User_users_by_pk_classrooms_students | undefined>()
  const [isEditingName, setIsEditingName] = useState<User_users_by_pk_classrooms_students | undefined>()
  const [isResettingPassword, setIsResettingPassword] = useState<User_users_by_pk_classrooms_students | undefined>()
  const [isDroppingStudent, setIsDroppingStudent] = useState<User_users_by_pk_classrooms_students | undefined>()
  const [isDeletingStudent, setIsDeletingStudent] = useState<User_users_by_pk_classrooms_students | undefined>()
  const [isViewingStudentReport, setIsViewingStudentReport] = useState<User_users_by_pk_classrooms_students | undefined>()

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "display_name",
        sortDescFirst: true,
      },
      {
        Header: "Accuracy",
        accessor: (user: User_users_by_pk_classrooms_students) => `${Math.ceil(user.accuracy)}%`,
        sortDescFirst: false,
        sortType: (a: any, b: any) => a.original.accuracy - b.original.accuracy,
      },
      {
        Header: "Level",
        accessor: (user: User_users_by_pk_classrooms_students) => user.level,
        sortType: (a: any, b: any) => a.original.level - b.original.level,
        sortDescFirst: false,
      },
      {
        Header: "Stars",
        accessor: (user: User_users_by_pk_classrooms_students) => withCommas(user.stars),
        sortType: (a: any, b: any) => a.original.stars - b.original.stars,
        sortDescFirst: false,
      },
      {
        Header: "Words",
        accessor: (user: User_users_by_pk_classrooms_students) => user,
        sortDescFirst: false,
        Cell: (user: { value: User_users_by_pk_classrooms_students }) => (
          <div className="text-s m-0 position-relative">
            <p className="m-0">
              {hasPremium ? user.value.concepts_discovered : Math.min(user.value.concepts_discovered, 100)}{" "}
              <span className="text--gray7">out of</span> {withCommas(hasPremium ? CONFIG.TOTAL_WORDS : CONFIG.BASE_WORDS)}
            </p>

            {!hasPremium && (
              <p
                onClick={() => navigate("/premium")}
                style={{ top: "25px" }}
                className="text-xs bold text--purple position-absolute r-0 hover-underline pointer"
              >
                Unlock all words
              </p>
            )}
          </div>
        ),
      },
      {
        Header: "Report",
        accessor: (user: User_users_by_pk_classrooms_students) => user,
        Cell: (user: { value: User_users_by_pk_classrooms_students }) => {
          const hasReports = user.value.reports.length > 0
          return (
            <div className="d-flex align-items-center justify-content-end height-70px w-100">
              <div className="d-flex flex-column align-items-center">
                <Button className="single-line mt-2" color="primary" disabled={!hasReports} onClick={() => handleViewStudentReport(user.value)}>
                  View report
                </Button>

                {hasReports && <p className="text-xxs text--gray6 mb-0">updated {moment(user.value.reports[0].created_at).format("M/D/YY")}</p>}
              </div>
            </div>
          )
        },
      },
    ],
    [student]
  )

  const ALIGN_RIGHT_HEADERS = ["Level", "Stars", "Accuracy", "Words", "Report"]

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: classroom.students,
    },
    useSortBy
  )

  const handleDownloadStudentReport = () => {
    if (!accessToken || !isViewingStudentReport) return

    dispatch(downloadStudentReportAction(accessToken!, isViewingStudentReport.id))
    postSlackMessage(`${user?.display_name} downloaded report for ${isViewingStudentReport.display_name}.`)
  }

  const handleViewStudentReport = (student: User_users_by_pk_classrooms_students) => {
    if (!accessToken || !user) return

    const report = student.reports[0]
    setIsViewingStudentReport(student)
    dispatch(fetchPresignedUrlForReportAction(accessToken, report.aws_s3_url))
    const event = { user_id: user.id, user_event_type_id: UserEvent.OpenedIndividualReport, additional_data: { student_id: student.id } }
    dispatch(insertUserEventAction(accessToken, event))
    postSlackMessage(`${user.display_name} opened report for ${student.display_name} from ${report.created_at}.`)
  }

  const editName = async (value: string) => {
    setLoading(true)
    await dispatch(updateUserDisplayNameAction(accessToken!, isEditingName?.id!, value))
    await dispatch(fetchUserAction(accessToken!, user!.id))
    setLoading(false)
    setIsEditingName(undefined)
    setStudent(undefined)
  }

  const editPassword = async (value: string) => {
    setLoading(true)
    await dispatch(updatePasswordAction(accessToken!, isResettingPassword?.id!, value))
    setLoading(false)
    setIsResettingPassword(undefined)
    setStudent(undefined)
  }

  const dropStudent = async () => {
    setLoading(true)
    await dispatch(dropStudentAction(accessToken!, isDroppingStudent?.id!))
    await dispatch(fetchUserAction(accessToken!, user!.id))
    setLoading(false)
    setIsDroppingStudent(undefined)
    setStudent(undefined)
  }

  const deleteStudent = async () => {
    setLoading(true)
    await dispatch(deleteStudentAction(accessToken!, isDeletingStudent?.id!))
    await dispatch(fetchUserAction(accessToken!, user!.id))
    setLoading(false)
    setIsDeletingStudent(undefined)
    setStudent(undefined)
  }

  const switchClass = async (id: string, classroomId: number) => {
    setLoading(true)
    await dispatch(updateUserClassroomAction(accessToken!, id, classroomId))
    await dispatch(fetchUserAction(accessToken!, user!.id))
    setLoading(false)
    dispatch(setNotificationAction(NotificationId.StudentClassSwitched))
  }

  const hasMultipleStudents = classroom.students.length > 1

  const unarchivedClassrooms = (user?.classrooms || []).filter((c) => !c.archived)

  const hasOtherUnarchivedClassrooms = unarchivedClassrooms.filter((c) => c.id !== classroom.id).length > 0

  return (
    <div>
      {presignedUrlForReport && (
        <ReportModal url={presignedUrlForReport} downloadCsv={handleDownloadStudentReport} handleClose={() => setIsViewingStudentReport(undefined)} />
      )}

      <EditModal
        loading={loading}
        isEditing={!isUndefined(isEditingName)}
        placeholder="Name..."
        save={editName}
        setIsEditing={setIsEditingName}
        title={`Edit ${isEditingName?.display_name}'s name`}
      />

      <EditModal
        body="Password must be at least 7 characters."
        loading={loading}
        isEditing={!isUndefined(isResettingPassword)}
        isInvalid={(value) => value.length < 7}
        placeholder="New password..."
        save={editPassword}
        setIsEditing={setIsResettingPassword}
        title={`Edit ${isResettingPassword?.display_name}'s password`}
      />

      <DangerModal
        body={`Are you sure you want to drop ${isDroppingStudent?.display_name} from your class?`}
        confirm={dropStudent}
        confirmText={`Drop ${isDroppingStudent?.display_name}`}
        loading={loading}
        isOpen={!isUndefined(isDroppingStudent)}
        setIsOpen={setIsDroppingStudent}
        title={`Drop ${isDroppingStudent?.display_name}?`}
      />

      <DangerModal
        body={`Are you sure you want to delete ${isDeletingStudent?.display_name} from your class? This will delete all of the student's data and CANNOT be undone.`}
        confirm={deleteStudent}
        confirmText={`Delete ${isDeletingStudent?.display_name}`}
        loading={loading}
        isOpen={!isUndefined(isDeletingStudent)}
        setIsOpen={setIsDeletingStudent}
        title={`Delete ${isDeletingStudent?.display_name}?`}
      />

      <table style={{ tableLayout: "fixed" }} className="w-100" cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any, idx: number) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()} className="px-3">
              {headerGroup.headers.map((column: any, idx2: number) => {
                const isSorted = column.isSorted
                const isReverse = column.isSortedDesc
                const highlightUp = isSorted && (column.sortDescFirst ? !isReverse : isReverse)
                const highlightDown = isSorted && !highlightUp

                const isStars = column.Header === "Stars"

                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))} key={idx2} className="nowrap pb-4" colSpan={2}>
                    <div className={`d-flex align-items-center ${ALIGN_RIGHT_HEADERS.includes(column.Header) ? "justify-content-end" : ""}`}>
                      <div className="position-relative">
                        <p id={isStars ? "starsTooltip" : ""} className="m-0 text-m bold text-primary overflow-ellipses">
                          {column.Header}
                        </p>

                        {isStars && (
                          <div style={{ top: "22px" }} className="d-flex align-items-center r-0 position-absolute ">
                            <p className="mb-0 text-xxs text--gray6">correct answers</p>

                            <WrappedTooltip
                              message="Students earn 1 star every time they answer a question correctly without clicking hint."
                              target="starsTooltip"
                            />
                          </div>
                        )}
                      </div>

                      {hasMultipleStudents && column.Header !== "Report" && (
                        <div onClick={() => column.toggleSortBy()} className="ml-2 hide-mobile pointer">
                          <div className={`arrow arrow-up-sort ${highlightUp ? "arrow-up-sort-selected" : ""}`} />
                          <div className={`arrow arrow-down-sort ${highlightDown ? "arrow-down-sort-selected" : ""}`} />
                        </div>
                      )}
                    </div>
                  </th>
                )
              })}

              <th colSpan={1} />
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, idx: number) => {
            prepareRow(row)

            const s: User_users_by_pk_classrooms_students = row.original
            const backgroundColor = hasMultipleStudents ? (idx % 2 === 0 ? colors.gray : "white") : colors.gray
            const isEditing = student?.id === s.id

            return (
              <tr className={`${idx === 0 ? "rounded-top" : ""} px-3`} style={{ backgroundColor }} key={idx} {...row.getRowProps()}>
                {row.cells.map((cell: any, idx2: number) => {
                  return (
                    <td
                      className={`height-70px ${idx2 === 0 ? "pl-3" : ""} ${ALIGN_RIGHT_HEADERS.includes(cell.column.Header) ? "text-right" : ""}`}
                      key={idx2}
                      {...cell.getCellProps()}
                      colSpan={2}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}

                <td className="d-flex height-70px justify-content-end align-items-center pr-3" colSpan={1}>
                  <Dropdown
                    className="dropdown-menu-dots mx-2"
                    disabled={loading}
                    isOpen={isEditing}
                    toggle={() => setStudent(student ? undefined : s)}
                  >
                    <DropdownToggle>
                      <img className="icon-s" src={dots} />
                    </DropdownToggle>

                    <DropdownMenu right>
                      <DropdownItem className="m-1" onClick={() => setIsEditingName(s)}>
                        Edit name
                      </DropdownItem>

                      <DropdownItem className="m-1" onClick={() => setIsResettingPassword(s)}>
                        Edit password
                      </DropdownItem>

                      <DropdownItem className="m-1" onClick={() => setIsDroppingStudent(s)}>
                        Drop from class
                      </DropdownItem>

                      <DropdownItem className="m-1" onClick={() => setIsDeletingStudent(s)}>
                        Delete student
                      </DropdownItem>

                      {hasOtherUnarchivedClassrooms && <DropdownItem divider />}

                      {unarchivedClassrooms
                        .filter((c) => c.id !== classroom.id)
                        .map((c) => (
                          <DropdownItem key={c.id} onClick={() => switchClass(s.id, c.id)} className="text-xxs m-1">
                            Move to {c.display_name}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
            )
          })}

          {hasMultipleStudents && (
            <tr className="text-s text-uppercase bold bg--primary2 text-white rounded-bottom">
              <td colSpan={2} className="py-3 pl-3">
                Average
              </td>
              <td colSpan={2} className="py-3 text-right">
                {Math.ceil(mean(classroom.students.filter((c) => c.concepts_discovered).map((c) => c.accuracy))) || 100}%
              </td>
              <td colSpan={2} className="py-3 text-right">
                {Math.ceil(meanBy(classroom.students, "level"))}
              </td>
              <td colSpan={2} className="py-3 text-right">
                {withCommas(Math.ceil(meanBy(classroom.students, "stars")))}
              </td>
              <td colSpan={2} className="py-3 pr-3 text-right">
                {Math.ceil(meanBy(classroom.students, "concepts_discovered"))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
