import React from "react"
import { useTable } from "react-table"

import { Cell, Header, HeaderCell, TextCell, Table } from "./table"

interface Props {
  data: any[]
  columns: any[]
}

const defaultColumn: any = {
  TextCell,
}

export default function SimpleTable(props: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: props.columns,
    data: props.data,
    defaultColumn,
  })

  return (
    <Table cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, idx: number) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <HeaderCell key={idx} {...column.getHeaderProps()}>
                <Header>{column.render("Header")}</Header>
              </HeaderCell>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, idx: number) => {
          prepareRow(row)

          return (
            <tr key={idx} {...row.getRowProps()}>
              {row.cells.map((cell: any, idx2: number) => {
                return (
                  <Cell className="text-left" key={idx2} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Cell>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
