import React, { useEffect, useState, useMemo } from "react"
import Select from "react-select"
import partition from "lodash/partition"
import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AddStudents from "../components/classes/addStudents"
import ArchivedTable from "../components/classes/archivedTable"
import CONFIG from "../config"
import DangerModal from "../components/common/modals/danger"
import EditModal from "../components/common/modals/edit"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleTable from "../components/common/tables/simple"
import StudentsTable from "../components/classes/studentsTable"
import { Option } from "../interfaces/option"
import { QueryName } from "../hasura/queryNames"
import { UserEvent } from "../lib/userEventTypes"
import { User_users_by_pk_classrooms } from "../hasura/queries/types/User"
import { insertUserEventAction, userSelector, UserState } from "../hasura/slices/user"
import { maxClassroomsReached, pluralize, searchQueryParams, unarchivedClassroomOptions } from "../lib/helpers"
import { usePrevious } from "../hooks/usePrevious"

// @ts-ignore
import starIcon from "../../lib/images/star.svg"

import {
  archiveClassroomAction,
  classroomSelector,
  ClassroomState,
  insertClassroomAction,
  loginCardsAction,
  loginsRawAction,
  updateClassroomDisplayNameAction,
} from "../hasura/slices/classroom"

export default function TeacherHome() {
  const dispatch = useDispatch()

  const { accessToken, user, hasPremium, isQuerying }: UserState = useSelector(userSelector)
  const classroomState: ClassroomState = useSelector(classroomSelector)

  const archivedOption: Option = { label: "Archived Classes", value: null }

  const [archivedClassrooms, unArchivedClassrooms] = partition(user?.classrooms, (c) => c.archived)
  const hasArchivedClassrooms = archivedClassrooms.length > 0

  const loginColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Password",
        accessor: (login: any) => (login.google ? "Use google to log in!" : login.password),
      },
    ],
    []
  )

  const options: Option[] =
    unarchivedClassroomOptions(user)
      // @ts-ignore
      .concat(hasArchivedClassrooms ? archivedOption : null)
      .filter((c) => c) || []

  const [classroom, setClassroom] = useState<User_users_by_pk_classrooms | undefined>()
  const [displayLogins, setDisplayLogins] = useState(false)
  const [displayReport, setDisplayReport] = useState(false)
  const [isAddingStudents, setIsAddingStudents] = useState(searchQueryParams("add") === "t")
  const [isArchivingClassroom, setIsArchivingClassroom] = useState(false)
  const [isCreatingClass, setIsCreatingClassroom] = useState(searchQueryParams("new") === "t")
  const [isEditingClassroom, setIsEditingClassroom] = useState(false)
  const [isViewingArchivedClassrooms, setIsViewingArchivedClassrooms] = useState(false)

  const previousUser = usePrevious(user)

  /*
    Effects
  */

  useEffect(() => {
    if (!user || !accessToken || previousUser) return

    const event = { user_id: user.id, user_event_type_id: UserEvent.ViewedClass }
    dispatch(insertUserEventAction(accessToken, event))
  }, [user, accessToken])

  useEffect(() => {
    if (!user) return

    const newClassroom = (user?.classrooms || []).find(
      (c) => !(previousUser?.classrooms || []).map((c2: User_users_by_pk_classrooms) => c2.id).includes(c.id)
    )

    if (previousUser && newClassroom) {
      setClassroom(newClassroom)
    } else {
      const id = classroom?.id || parseInt(searchQueryParams("i") || "", 10)
      setClassroom(user.classrooms.find((c) => !c.archived && c.id === id) || user.classrooms[0])
    }
  }, [user])

  /*
    Methods
  */

  const archiveClassroom = async () => {
    await dispatch(archiveClassroomAction(accessToken!, classroom?.id!, user?.id!, true))
    setIsArchivingClassroom(false)
  }

  const createClassroom = async (display_name: string) => {
    await dispatch(insertClassroomAction(accessToken!, display_name, user?.id!))
    setIsCreatingClassroom(false)
  }

  const handleDownloadLogins = () => dispatch(loginCardsAction(accessToken!, classroom?.id!))

  const handleDownloadReport = () => {
    console.log("TODO: createReportAction")
  }

  const handleViewLogins = () => {
    if (!displayLogins) dispatch(loginsRawAction(accessToken!, classroom?.id!))

    setDisplayLogins(!displayLogins)
    if (!user || !accessToken || displayLogins) return

    const event = { user_id: user.id, user_event_type_id: UserEvent.ViewedLogins }
    dispatch(insertUserEventAction(accessToken, event))
  }

  const updateClassroom = (display_name: string) => {
    dispatch(updateClassroomDisplayNameAction(accessToken!, classroom?.id!, display_name, user?.id!))
    setIsEditingClassroom(false)
  }

  const classroomSelect = (
    <Select
      className="select-w-m"
      options={options}
      value={isViewingArchivedClassrooms ? archivedOption : options.find((o) => o.value === classroom?.id)}
      // @ts-ignore
      onChange={(option: Option) => {
        if (option.value === archivedOption.value) {
          setIsViewingArchivedClassrooms(true)
        } else {
          setClassroom(user?.classrooms.find((c) => c.id === option.value))
          setIsViewingArchivedClassrooms(false)
        }
      }}
    />
  )

  if (isViewingArchivedClassrooms) {
    return (
      <Layout>
        <SEO title="Manage Classes" />

        <div className="w-100 py-3 max-width-1100px mx-auto">
          <div className="d-flex mt-2">{classroomSelect}</div>

          <ArchivedTable setIsViewingArchivedClassrooms={setIsViewingArchivedClassrooms} classrooms={archivedClassrooms} />
        </div>
      </Layout>
    )
  }

  const studentsCount = classroom?.students.length || 0
  const noStudents = studentsCount === 0
  const maxClassSizeReached = studentsCount >= CONFIG.MAX_CLASS_SIZE && !CONFIG.FILTER_HOTJAR_TEACHER_IDS.includes(user?.id || "")

  return (
    <Layout>
      <SEO title="Manage Classes" />

      {classroom && (
        <div className="w-100 pt-3 pb-5 max-width-1100px mx-auto">
          {displayReport && (
            <Modal id="report-modal" toggle={() => setDisplayReport(false)} size="xl" isOpen={displayReport}>
              <div className="d-flex justify-content-between">
                <div />

                <div>
                  <Button onClick={handleDownloadReport} color="primary mr-3">
                    Download
                  </Button>

                  <Button outline onClick={() => setDisplayReport(false)} color="secondary">
                    Close
                  </Button>
                </div>
              </div>

              <div className="mt-3" id="report-container">
                {/* <Report id={classroom.id} /> */}
              </div>
            </Modal>
          )}

          <Modal size="lg" toggle={handleViewLogins} isOpen={displayLogins}>
            <ModalBody style={{ minHeight: "50vh" }} className="text-center">
              <div className="flex-center">
                <div className="flex-even" />

                <h1 className="flex-even main-header">Logins</h1>

                <div className="flex-even text-right">
                  <Button className="mb-2" disabled={classroomState.isQuerying[QueryName.LoginCards]} onClick={handleDownloadLogins} color="primary">
                    Download
                    {classroomState.isQuerying[QueryName.LoginCards] && <Spinner className="ml-2" size="sm" color="white" />}
                  </Button>

                  <Button className="ml-2 mb-2" outline color="link" onClick={handleViewLogins}>
                    Exit
                  </Button>
                </div>
              </div>

              {classroomState.loginsRaw.length ? <SimpleTable data={classroomState.loginsRaw} columns={loginColumns} /> : <Spinner color="primary" />}
            </ModalBody>
          </Modal>

          <EditModal
            disabled={false}
            confirmText="Edit class name"
            isEditing={isEditingClassroom}
            placeholder="Name..."
            save={updateClassroom}
            setIsEditing={setIsEditingClassroom}
            title="Edit Class Name"
          />

          {isAddingStudents && (
            <AddStudents maxClassSizeReached={maxClassSizeReached} classroom={classroom} setIsAddingStudents={setIsAddingStudents} />
          )}

          <EditModal
            disabled={classroomState.isQuerying[QueryName.InsertClassroom]}
            confirmText="Add new class"
            isEditing={isCreatingClass}
            placeholder="Name..."
            save={createClassroom}
            setIsEditing={setIsCreatingClassroom}
            title="Add New Class"
          />

          <DangerModal
            loading={isQuerying[QueryName.FetchUser] || classroomState.isQuerying[QueryName.ArchiveClassroom]}
            title={`Archive ${classroom?.display_name}`}
            body="Archiving a class means your students will be unable to view it anymore. Generally, classes should only be archived after the semester or school year is over. Are you sure you want to archive test class?"
            confirmText="Archive class"
            isOpen={isArchivingClassroom}
            setIsOpen={setIsArchivingClassroom}
            confirm={archiveClassroom}
          />

          <div className="d-flex mt-2 flex-wrap justify-content-start mb-4">
            <div className="mr-3">
              <div className="d-flex">
                {classroomSelect}

                <Button
                  onClick={() => {
                    if (maxClassroomsReached(hasPremium, user)) {
                      navigate("/premium")
                    } else {
                      setIsCreatingClassroom(true)
                    }
                  }}
                  color="success"
                  className="select-height ml-3"
                >
                  New class
                </Button>
              </div>

              <p className="d-flex align-items-end my-1 text-muted">
                {pluralize("student", classroom?.students.length)} ({Math.max(0, CONFIG.MAX_CLASS_SIZE - studentsCount)} available) • Class code:{" "}
                <span onClick={() => setIsAddingStudents(true)} className="text-link ml-1">
                  {classroom?.join_code}
                </span>
              </p>

              <div className="d-flex justify-content-between">
                {!noStudents && (
                  <div>
                    <Button color="success" onClick={() => setIsAddingStudents(true)}>
                      Add students
                    </Button>

                    <Button
                      disabled={classroomState.isQuerying[QueryName.LoginsRaw]}
                      onClick={handleViewLogins}
                      outline
                      className="ml-3"
                      color="primary"
                    >
                      View logins
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div>
              <Button onClick={() => setIsEditingClassroom(true)} outline color="link" className="select-height">
                Edit class
              </Button>

              {unArchivedClassrooms.length > 1 && (
                <Button onClick={() => setIsArchivingClassroom(true)} outline color="link" className="select-height">
                  Archive class
                </Button>
              )}
            </div>
          </div>

          {noStudents ? (
            <div className="mt-3">
              <p className="mb-1 gray8 text-xl garamond">Let's add your students to {classroom.display_name}!</p>

              <Button size="lg" color="primary" onClick={() => setIsAddingStudents(true)}>
                Add students
              </Button>
            </div>
          ) : (
            <StudentsTable classroom={classroom} />
          )}
        </div>
      )}
    </Layout>
  )
}
