import React, { useEffect, useMemo, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import last from "lodash/last"

import AuthService from "../../services/auth"
import CONFIG from "../../config"
import { User_users_by_pk_classrooms } from "../../hasura/queries/types/User"
import { copyToClipboard, formatUsername } from "../../lib/helpers"
import { useFocus } from "../../hooks/useFocus"
import SimpleTable from "../common/tables/simple"

import {
  fetchUserAction,
  fetchUsersStartingWithAction,
  SignUpParams,
  unsetSignUpParamsAction,
  userSelector,
  UserState,
} from "../../hasura/slices/user"

// @ts-ignore
import copyIcon from "../../lib/images/copy.png"
// @ts-ignore
import successIcon from "../../lib/images/success.png"

interface Props {
  setIsAddingStudents: (isAddingStudents: boolean) => void
  classroom: User_users_by_pk_classrooms
  maxClassSizeReached: boolean
}

export default function AddStudents(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, signUpParams, user }: UserState = useSelector(userSelector)

  const [inputRef, setInputFocus] = useFocus()

  const code = props.classroom.join_code

  const [addStudentsManually, setAddStudentsManually] = useState(false)
  const [addedStudents, setAddedStudents] = useState<SignUpParams[]>([])
  const [copiedCodeToClipboard, setCopiedCodeToClipboard] = useState(false)
  const [copiedLinkToClipboard, setCopiedLinkToClipboard] = useState(false)
  const [isNetworking, setIsNetworking] = useState(false)
  const [name, setName] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  const link = `${CONFIG.DOMAIN}/signup/student?code=${code}`.split("://")[1]

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Username",
        accessor: (s: SignUpParams) => formatUsername(s.email),
      },
      {
        Header: "Password",
        accessor: (s: SignUpParams) => s.password,
      },
    ],
    []
  )

  useEffect(() => {
    if (!signUpParams) return

    new AuthService().signUpStudent(signUpParams, props.classroom.id, (error?: string) => {
      setIsNetworking(false)

      if (error) {
        setError(error)
      } else {
        dispatch(fetchUserAction(accessToken!, user?.id!))
        setName(undefined)
        setAddedStudents(addedStudents.concat(signUpParams))
      }
    })
  }, [signUpParams])

  useEffect(() => {
    return () => {
      dispatch(unsetSignUpParamsAction())
    }
  }, [])

  useEffect(() => {
    // @ts-ignore
    setInputFocus()
  }, [addedStudents])

  const copyLinkToClipboard = () => {
    copyToClipboard(link)
    setCopiedLinkToClipboard(true)
  }

  const copyCodeToClipboard = () => {
    copyToClipboard(code)
    setCopiedCodeToClipboard(true)
  }

  const addStudent = () => {
    setIsNetworking(true)
    dispatch(fetchUsersStartingWithAction(name!))
  }

  const isAddingMultipleStudents = (name || "").split(" ").length > 3

  return (
    <Modal
      autoFocus={false}
      fade={false}
      centered
      zIndex={999999}
      isOpen={true}
      toggle={() => {
        setError(undefined)
        props.setIsAddingStudents(false)
      }}
    >
      <ModalHeader className="bold">Create Accounts for Students</ModalHeader>

      {addStudentsManually ? (
        <ModalBody>
          <p className="m-0">When you create accounts for students manually, they must log in with the username and password shown below.</p>

          <Input
            innerRef={inputRef}
            onChange={(e) => {
              setError(undefined)
              setName(e.target.value)
            }}
            bsSize="lg"
            className="mt-2 data-hj-allow"
            placeholder="Name..."
            value={name || ""}
          />

          {addedStudents.length > 0 && (
            <div className="mt-2 d-flex align-items-center justify-content-start">
              <img className="icon-xs mr-2" src={successIcon} />
              <p className="m-0 text-success text-s">{last(addedStudents)?.displayName} has been added.</p>
            </div>
          )}

          {addedStudents.length > 0 && (
            <div className="overflow-scroll" style={{ maxHeight: "30vh" }}>
              <SimpleTable data={addedStudents} columns={columns} />
            </div>
          )}

          <Button
            className="mt-2"
            color="primary"
            disabled={!name || isAddingMultipleStudents || isNetworking || props.maxClassSizeReached}
            onClick={addStudent}
          >
            Add Student
          </Button>

          {isAddingMultipleStudents && <p className="text-danger text-xs mb-0 mt-2">Please add one student at a time.</p>}

          {error && <p className="text-danger text-xs mb-0 mt-2">{error}</p>}

          {props.maxClassSizeReached && (
            <p className="text-danger text-xs mb-0 mt-2">Max class size is {CONFIG.MAX_CLASS_SIZE}. Create a new class to add more students.</p>
          )}
        </ModalBody>
      ) : (
        <ModalBody>
          <div>
            <p className="bold m-0">Send Invite Link (recommended)</p>
            <p className="m-0">Share this link with your students to join your class.</p>
            <p className="text-primary mb-1">{link}</p>

            <Button onClick={copyLinkToClipboard} className="mt-2 d-flex align-items-center button-no-hover" outline color="primary">
              {!copiedLinkToClipboard && <img style={{ height: "20px", width: "auto" }} src={copyIcon} className="mr-1" />}{" "}
              {copiedLinkToClipboard ? "Link Copied!" : "Copy Link"}
            </Button>
          </div>

          <div className="my-3">
            <p className="bold m-0">Share Class Code</p>
            <p className="m-0">Have your students enter this code to join your class.</p>
            <p className="text-primary mb-1">{code}</p>

            <Button onClick={copyCodeToClipboard} className="mt-2 d-flex align-items-center button-no-hover" outline color="primary">
              {!copiedCodeToClipboard && <img style={{ height: "20px", width: "auto" }} src={copyIcon} className="mr-1" />}{" "}
              {copiedCodeToClipboard ? "Code Copied!" : "Copy Code"}
            </Button>
          </div>

          <div>
            <p className="bold m-0">Add Students Myself</p>
            <p onClick={() => setAddStudentsManually(true)} role="button" className="text-primary mb-0">
              I would like to add my students manually.
            </p>
          </div>
        </ModalBody>
      )}

      <ModalFooter>
        <Button
          color="primary"
          disabled={isNetworking || ((name?.length || 0) > 0 && !addedStudents.length)}
          onClick={() => props.setIsAddingStudents(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}
