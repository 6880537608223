import React, { useState } from "react"
import { Tooltip } from "reactstrap"

interface Props {
  target: string
  message: string
}

// @ts-ignore
import tooltipIcon from "../../lib/images/tooltip.svg"

export default function WrappedTooltip(props: Props) {
  const [display, setDisplay] = useState(false)

  return (
    <div className="ml-1 flex-center" onPointerEnter={() => setDisplay(true)} onPointerLeave={() => setDisplay(false)}>
      <img className="icon-s p-1" src={tooltipIcon} />

      {display && (
        <Tooltip arrowClassName="tooltip-arrow-light" fade={false} innerClassName="tooltip-primary" isOpen target={props.target}>
          <p className="text-xs m-0">{props.message}</p>
        </Tooltip>
      )}
    </div>
  )
}
